import { template as template_84942d8b47964ddf9a7206d95d8b131d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_84942d8b47964ddf9a7206d95d8b131d(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
