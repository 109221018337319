import { template as template_094aed8e243f4ebd80b1c2e1b25fad9b } from "@ember/template-compiler";
const SidebarSectionMessage = template_094aed8e243f4ebd80b1c2e1b25fad9b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
