import { template as template_df344d3441d74079a1755d12615bbe76 } from "@ember/template-compiler";
const FKLabel = template_df344d3441d74079a1755d12615bbe76(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
